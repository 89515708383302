import axios from 'axios';
import { getApiUrl } from './config'; // Adjust this import path as needed

const api = axios.create({
  baseURL: getApiUrl(),
  // Remove the withCredentials option
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response interceptor
api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    // Handle unauthorized access (e.g., redirect to login page)
    // You might want to use your routing library here, e.g., React Router
    window.location = '/login';
  }
  return Promise.reject(error);
});

export default api;
