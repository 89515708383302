import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from '../config'; // Add this import
import styles from './BusinessEditor.module.css';

function BusinessEditor() {
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBusiness();
  }, [id]);

  const fetchBusiness = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/businesses/${id}`);
      console.log('Fetched business:', response.data);
      setBusiness(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching business:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch business: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setBusiness({ ...business, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${getApiUrl()}/api/businesses/${id}`, business);
      console.log('Business updated:', response.data);
      alert('Business updated successfully');
      fetchBusiness();
    } catch (error) {
      console.error('Error updating business:', error);
      console.error('Error response:', error.response);
      setError('Failed to update business: ' + (error.response?.data?.message || error.message));
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!business) return <div>Business not found</div>;

  return (
    <div className={styles.editorContainer}>
      <h2>Edit Business</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={business.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="website">Website:</label>
          <input
            type="text"
            id="website"
            name="website"
            value={business.website}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="facebookURL">Facebook URL:</label>
          <input
            type="text"
            id="facebookURL"
            name="facebookURL"
            value={business.facebookURL}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="instagramURL">Instagram URL:</label>
          <input
            type="text"
            id="instagramURL"
            name="instagramURL"
            value={business.instagramURL}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="XURL">X URL:</label>
          <input
            type="text"
            id="XURL"
            name="XURL"
            value={business.XURL}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="zipCode">Zip Code:</label>
          <input
            type="text"
            id="zipCode"
            name="zipCode"
            value={business.zipCode || ''}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className={styles.submitButton}>Update Business</button>
      </form>
    </div>
  );
}

export default BusinessEditor;
