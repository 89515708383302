import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from '../config'; // Add this import
import Table from './common/Table/Table';
import styles from './ContentManager.module.css';

function ContentManager() {
  const [contentCollections, setContentCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newCollectionName, setNewCollectionName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchContentCollections();
  }, []);

  const fetchContentCollections = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${getApiUrl()}/api/content-collections`);
      console.log('Fetched content collections:', response.data);
      setContentCollections(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching content collections:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch content collections: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  };

  const handleAddContentCollection = async () => {
    if (!newCollectionName.trim()) {
      alert('Please enter a name for the new content collection');
      return;
    }
    try {
      const response = await axios.post(`${getApiUrl()}/api/content-collections`, { name: newCollectionName });
      console.log('Added content collection:', response.data);
      setContentCollections([...contentCollections, response.data.data]);
      setNewCollectionName('');
    } catch (error) {
      console.error('Error adding content collection:', error);
      console.error('Error response:', error.response);
      setError('Failed to add content collection: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleDeleteCollection = async (collectionId) => {
    if (window.confirm('Are you sure you want to delete this collection? This will also delete all associated posts.')) {
      try {
        await axios.delete(`${getApiUrl()}/api/content-collections/${collectionId}`);
        setContentCollections(contentCollections.filter(collection => collection._id !== collectionId));
      } catch (error) {
        console.error('Error deleting content collection:', error);
        console.error('Error response:', error.response);
        setError('Failed to delete content collection: ' + (error.response?.data?.message || error.message));
      }
    }
  };

  const handleCollectionClick = (collection) => {
    navigate(`/dashboard/collection/${collection._id}/posts`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  const headers = [
    { key: 'name', label: 'Name' },
    { key: 'createdAt', label: 'Created At' },
    { key: 'updatedAt', label: 'Updated At' },
    { key: 'actions', label: 'Actions' },
  ];

  const formattedContentCollections = contentCollections.map(collection => ({
    ...collection,
    createdAt: new Date(collection.createdAt).toLocaleString(),
    updatedAt: new Date(collection.updatedAt).toLocaleString(),
    actions: (
      <button onClick={(e) => {
        e.stopPropagation();
        handleDeleteCollection(collection._id);
      }} className={styles.deleteButton}>
        Delete
      </button>
    ),
  }));

  return (
    <div className={styles.contentManagerContainer}>
      <div className={styles.header}>
        <h1>Content Collections</h1>
        <div className={styles.addCollectionForm}>
          <input
            type="text"
            value={newCollectionName}
            onChange={(e) => setNewCollectionName(e.target.value)}
            placeholder="New collection name"
            className={styles.input}
          />
          <button onClick={handleAddContentCollection} className={styles.addButton}>
            Add Content Collection
          </button>
        </div>
      </div>
      {error && <div className={styles.error}>{error}</div>}
      {contentCollections.length > 0 ? (
        <Table 
          headers={headers} 
          data={formattedContentCollections} 
          keyField="_id"
          onRowClick={handleCollectionClick}
        />
      ) : (
        <p>No content collections found.</p>
      )}
    </div>
  );
}

export default ContentManager;
