import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from '../config'; // Add this import
import Table from './common/Table/Table';
import businessesStyles from './Businesses.module.css';

function Businesses() {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const fetchBusinesses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${getApiUrl()}/api/businesses`);
      console.log('Fetched businesses:', response.data);
      setBusinesses(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching businesses:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch businesses: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  };

  const handleAddBusiness = () => {
    navigate('/dashboard/new-business');
  };

  const handleRowClick = (business) => {
    navigate(`/dashboard/business-editor/${business._id}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className={businessesStyles.error}>{error}</div>;

  const headers = [
    { key: 'name', label: 'Name' },
    { key: 'website', label: 'Website' },
    { key: 'zipCode', label: 'Zip Code' },
    { key: 'createdAt', label: 'Created At' },
    { key: 'updatedAt', label: 'Updated At' }
  ];

  const formattedBusinesses = businesses.map(business => ({
    ...business,
    createdAt: business.createdAt ? new Date(business.createdAt).toLocaleDateString() : 'N/A',
    updatedAt: business.updatedAt ? new Date(business.updatedAt).toLocaleDateString() : 'N/A'
  }));

  return (
    <div className={businessesStyles.businessesContainer}>
      <div className={businessesStyles.header}>
        <h1>Businesses</h1>
        <button onClick={handleAddBusiness} className={businessesStyles.addButton}>
          Add Business
        </button>
      </div>
      {businesses.length > 0 ? (
        <Table 
          headers={headers} 
          data={formattedBusinesses} 
          keyField="_id" 
          onRowClick={handleRowClick}
        />
      ) : (
        <p>No businesses found.</p>
      )}
    </div>
  );
}

export default Businesses;
