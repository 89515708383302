import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import Table from './common/Table/Table';
import styles from './Users.module.css';

function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/users');
      console.log('Fetched users:', response.data.data);
      setUsers(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  };

  const handleAddUser = () => {
    navigate('/dashboard/new-user');
  };

  const handleRowClick = (user) => {
    navigate(`/dashboard/edit-user/${user._id}`);
  };

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  const headers = [
    { key: 'email', label: 'Email' },
    { key: 'name', label: 'Name' },
    { key: 'phoneNumber', label: 'Phone' },
    { key: 'signupSource', label: 'Signup Source' },
    { key: 'status', label: 'Status' },
    { key: 'dateAdded', label: 'Date Added' }
  ];

  const formattedUsers = users.map(user => ({
    ...user,
    name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : 'N/A',
    dateAdded: user.dateAdded ? new Date(user.dateAdded).toLocaleDateString() : 'N/A'
  }));

  return (
    <div className={styles.usersContainer}>
      <div className={styles.header}>
        <h1>Users</h1>
        <button onClick={handleAddUser} className={styles.addButton}>
          Add User
        </button>
      </div>
      {error && <div className={styles.error}>{error}</div>}
      {users.length > 0 ? (
        <Table 
          headers={headers} 
          data={formattedUsers} 
          keyField="_id"
          onRowClick={handleRowClick}
        />
      ) : (
        <p>No users found.</p>
      )}
    </div>
  );
}

export default Users;