import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getApiUrl } from '../config'; // Add this import
import styles from './AddPostForm.module.css';

function AddPostForm({ onSubmit, onCancel }) {
  const [newPost, setNewPost] = useState({
    rawBizContent: '',
    businessId: '',
    type: '',
    aiOutput: '',
    finalCopy: ''
  });
  const [businesses, setBusinesses] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await axios.get(`${getApiUrl()}/api/businesses`);
        console.log('Fetched businesses:', response.data);
        setBusinesses(response.data.data || []);
      } catch (error) {
        console.error('Error fetching businesses:', error);
        console.error('Error response:', error.response);
        setError('Failed to fetch businesses: ' + (error.response?.data?.message || error.message));
      }
    };
    fetchBusinesses();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPost(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(newPost);
  };

  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContent}>
        <h2>Add New Post</h2>
        <form onSubmit={handleSubmit}>
          <textarea
            name="rawBizContent"
            value={newPost.rawBizContent}
            onChange={handleChange}
            placeholder="Raw Business Content"
            required
          />
          <select
            name="businessId"
            value={newPost.businessId}
            onChange={handleChange}
            required
          >
            <option value="">Select a business</option>
            {businesses.map(business => (
              <option key={business._id} value={business._id}>{business.name}</option>
            ))}
          </select>
          <select
            name="type"
            value={newPost.type}
            onChange={handleChange}
          >
            <option value="">Select type</option>
            <option value="Sale">Sale</option>
            <option value="Promotion">Promotion</option>
            <option value="Event">Event</option>
            <option value="Update">Update</option>
          </select>
          <textarea
            name="aiOutput"
            value={newPost.aiOutput}
            onChange={handleChange}
            placeholder="AI Output"
          />
          <textarea
            name="finalCopy"
            value={newPost.finalCopy}
            onChange={handleChange}
            placeholder="Final Copy"
          />
          <div className={styles.buttonGroup}>
            <button type="submit">Add Post</button>
            <button type="button" onClick={onCancel}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddPostForm;
