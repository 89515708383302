import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './NewTemplate.module.css';

function NewTemplate() {
  const [template, setTemplate] = useState({ name: '', subject: '', htmlContent: '' });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setTemplate({ ...template, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/email-templates', template);
      console.log('Template created:', response.data);
      navigate('/dashboard/templates');
    } catch (error) {
      console.error('Error creating template:', error);
      console.error('Error response:', error.response);
      setError('Failed to create template: ' + (error.response?.data?.message || error.message));
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Create New Email Template</h2>
      {error && <p className={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Template Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={template.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={template.subject}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="htmlContent">HTML Content:</label>
          <textarea
            id="htmlContent"
            name="htmlContent"
            value={template.htmlContent}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className={styles.submitButton}>Create Template</button>
      </form>
    </div>
  );
}

export default NewTemplate;
