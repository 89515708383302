import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from '../config';
import Table from './common/Table/Table';
import AddPostForm from './AddPostForm';
import styles from './CollectionPosts.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function CollectionPosts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddPostForm, setShowAddPostForm] = useState(false);
  const [processingAI, setProcessingAI] = useState(false);
  const [showPromptEditor, setShowPromptEditor] = useState(false);
  const [scanningFacebook, setScanningFacebook] = useState(false);
  const [prompt, setPrompt] = useState(`Here is the content relevant to this prompt ...

Business Name: {{businessName}}

Business Website: {{bizWebsite}}

Raw Content: {{rawBizContent}}

Type: {{type}}

Please turn the raw biz content into one sentence that describes the post in the following format. Start with the business name followed by HTML that links this business name to its business website. Then finish the sentence with a description of the raw content. For example, "<a href="https://littlecoyote.com" target="_blank">Little Coyote</a> is offering 20% off tacos this Friday." Notice that this sentence starts with the business name linked to the business website and is presented in a matter of fact way without any superfluous flowery language that might have been included in the raw post.`);

  const { collectionId } = useParams();
  const navigate = useNavigate();

  const fetchPosts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${getApiUrl()}/api/posts/collection/${collectionId}`);
      console.log('Fetched posts:', response.data);
      setPosts(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching posts:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch posts: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  }, [collectionId]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const handlePostClick = (post) => {
    navigate(`/dashboard/edit-post/${post._id}`);
  };

  const handleAddPost = () => {
    setShowAddPostForm(true);
  };

  const handleAddPostSubmit = async (newPost) => {
    try {
      const response = await axios.post(`${getApiUrl()}/api/posts`, {
        ...newPost,
        collectionId
      });
      console.log('Added new post:', response.data);
      setPosts([...posts, response.data.data]);
      setShowAddPostForm(false);
    } catch (error) {
      console.error('Error adding new post:', error);
      console.error('Error response:', error.response);
      setError('Failed to add new post: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleAddPostCancel = () => {
    setShowAddPostForm(false);
  };

  const handleGenerateAIContent = async () => {
    try {
      setProcessingAI(true);
      const response = await axios.post(`${getApiUrl()}/api/content-collections/${collectionId}/generate-ai-content`, { prompt });
      console.log('AI content generated:', response.data);
      fetchPosts(); // Refresh posts after AI generation
      setProcessingAI(false);
    } catch (error) {
      console.error('Error generating AI content:', error);
      console.error('Error response:', error.response);
      setError('Failed to generate AI content: ' + (error.response?.data?.message || error.message));
      setProcessingAI(false);
    }
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleFinalCopyEdit = async (postId, newFinalCopy) => {
    try {
      const response = await axios.patch(`${getApiUrl()}/api/posts/${postId}`, { finalCopy: newFinalCopy });
      console.log('Updated post:', response.data);
      
      setPosts(posts.map(post => 
        post._id === postId ? { ...post, finalCopy: newFinalCopy } : post
      ));
    } catch (error) {
      console.error('Error updating final copy:', error);
      setError('Failed to update final copy: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleTypeEdit = async (postId, newType) => {
    try {
      const response = await axios.patch(`${getApiUrl()}/api/posts/${postId}`, { type: newType });
      console.log('Updated post:', response.data);
      
      setPosts(posts.map(post => 
        post._id === postId ? { ...post, type: newType } : post
      ));
    } catch (error) {
      console.error('Error updating type:', error);
      setError('Failed to update type: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleScanFacebookPosts = async () => {
    try {
      setScanningFacebook(true);
      console.log(`Scanning Facebook posts from ${scanStartDate.toISOString()} to ${scanEndDate.toISOString()}`);
      const response = await axios.post(`${getApiUrl()}/api/content-collections/${collectionId}/scan-facebook-posts`, {
        startDate: scanStartDate.toISOString(),
        endDate: scanEndDate.toISOString()
      });
      console.log('Facebook posts scan response:', response.data);
      alert(`Scanned ${response.data.totalPostsScanned} posts. Added ${response.data.newPostsCount} new posts.`);
      fetchPosts(); // Refresh posts after scanning
      setScanningFacebook(false);
    } catch (error) {
      console.error('Error scanning Facebook posts:', error);
      console.error('Error response:', error.response);
      setError('Failed to scan Facebook posts: ' + (error.response?.data?.message || error.message));
      setScanningFacebook(false);
    }
  };

  const checkFacebookAccess = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/facebook-access/check`);
      console.log('Facebook Access Check:', response.data);
      alert(`Found ${response.data.count} Facebook access(es). ${response.data.sample ? `Sample: ${JSON.stringify(response.data.sample)}` : ''}`);
    } catch (error) {
      console.error('Error checking Facebook access:', error);
      alert('Error checking Facebook access. See console for details.');
    }
  };

  const testFacebookApi = () => {
    console.log('Test Facebook API button clicked');
    setCurrentAction('testApi');
    setShowPageIdInput(true);
  };

  const fetchFacebookPosts = () => {
    setCurrentAction('fetchPosts');
    setShowPageIdInput(true);
  };

  const handlePageIdSubmit = async () => {
    if (!pageIdInput) return;

    console.log(`Submitting Page ID: ${pageIdInput} for action: ${currentAction}`);

    if (currentAction === 'testApi') {
      try {
        console.log(`Sending request to: ${getApiUrl()}/api/facebook-access/test-api/${pageIdInput}`);
        const response = await axios.get(`${getApiUrl()}/api/facebook-access/test-api/${pageIdInput}`);
        console.log('Facebook API Test Response:', response.data);
        alert(`API Test Result: ${response.data.success ? 'Success' : 'Failed'}. Check console for details.`);
      } catch (error) {
        console.error('Error testing Facebook API:', error);
        console.error('Error response:', error.response);
        if (error.response && error.response.status === 401 && error.response.data.error.includes('token has expired')) {
          alert('Your Facebook access token has expired. Please reconnect your Facebook account.');
        } else {
          alert('Error testing Facebook API. See console for details.');
        }
      }
    } else if (currentAction === 'fetchPosts') {
      const startDate = scanStartDate.toISOString();
      const endDate = scanEndDate.toISOString();

      try {
        const response = await axios.get(`${getApiUrl()}/api/content-collections/fetch-facebook-posts/${pageIdInput}`, {
          params: { startDate, endDate }
        });
        console.log('Fetched Facebook posts:', response.data);
        setFetchedPosts(response.data.data.data || []);
        alert(`Fetched ${response.data.data.data.length} posts. Check console for details.`);
      } catch (error) {
        console.error('Error fetching Facebook posts:', error);
        alert('Error fetching Facebook posts. See console for details.');
      }
    }

    setShowPageIdInput(false);
    setPageIdInput('');
    setCurrentAction('');
  };

  const typeOptions = ['sale', 'promo', 'update', 'event'];

  const headers = [
    { key: 'rawBizContent', label: 'Raw Business Content' },
    { key: 'type', label: 'Type', editable: true, type: 'select', options: typeOptions, onEdit: handleTypeEdit },
    { key: 'aiDeterminedType', label: 'AI Determined Type' },
    { key: 'finalCopy', label: 'Final Copy', editable: true, onEdit: handleFinalCopyEdit },
    { key: 'include', label: 'Include' },
    { key: 'createdAt', label: 'Created At' },
  ];

  const columnWidths = {
    rawBizContent: '25%',
    type: '15%',
    aiDeterminedType: '15%',
    finalCopy: '25%',
    include: '10%',
    createdAt: '10%',
  };

  const formattedPosts = posts.map(post => ({
    ...post,
    type: post.type || 'Not set',
    aiDeterminedType: post.aiDeterminedType ? (post.aiDeterminedType.charAt(0).toUpperCase() + post.aiDeterminedType.slice(1)) : 'Not determined',
    include: post.include ? 'Yes' : 'No',
    createdAt: new Date(post.createdAt).toLocaleString(),
  }));

  const [scanStartDate, setScanStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7))); // Default to 7 days ago
  const [scanEndDate, setScanEndDate] = useState(new Date());
  const [showPageIdInput, setShowPageIdInput] = useState(false);
  const [pageIdInput, setPageIdInput] = useState('');
  const [fetchedPosts, setFetchedPosts] = useState([]);
  const [currentAction, setCurrentAction] = useState('');

  // Add these state variables
  const [newToken, setNewToken] = useState('');
  const [updatingToken, setUpdatingToken] = useState(false);

  // Add this function to your component
  const updateFacebookToken = async () => {
    if (!newToken) {
      alert('Please enter a new token');
      return;
    }

    const pageId = '162670296925699'; // Replace with your actual page ID
    setUpdatingToken(true);

    try {
      const response = await axios.post(`${getApiUrl()}/api/facebook-access/update-token/${pageId}`, {
        newToken: newToken
      });
      console.log('Token update response:', response.data);
      alert('Facebook token updated successfully');
      setNewToken(''); // Clear the input field
    } catch (error) {
      console.error('Error updating Facebook token:', error);
      alert('Error updating Facebook token. See console for details.');
    } finally {
      setUpdatingToken(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.collectionPostsContainer}>
      <div className={styles.header}>
        <h2>Posts for Collection</h2>
        <div className={styles.buttonGroup}>
          <button onClick={handleAddPost} className={styles.addButton}>
            Add New Post
          </button>
          <button onClick={() => setShowPromptEditor(!showPromptEditor)} className={styles.editPromptButton}>
            {showPromptEditor ? 'Hide Prompt Editor' : 'Edit Prompt'}
          </button>
          <button 
            onClick={handleGenerateAIContent} 
            className={styles.generateButton}
            disabled={processingAI}
          >
            {processingAI ? 'Generating...' : 'Generate AI Content'}
          </button>
          <div className={styles.datePickerContainer}>
            <DatePicker
              selected={scanStartDate}
              onChange={date => setScanStartDate(date)}
              selectsStart
              startDate={scanStartDate}
              endDate={scanEndDate}
              className={styles.datePicker}
            />
            <DatePicker
              selected={scanEndDate}
              onChange={date => setScanEndDate(date)}
              selectsEnd
              startDate={scanStartDate}
              endDate={scanEndDate}
              minDate={scanStartDate}
              className={styles.datePicker}
            />
          </div>
          <button 
            onClick={handleScanFacebookPosts} 
            className={styles.scanButton}
            disabled={scanningFacebook}
          >
            {scanningFacebook ? 'Scanning...' : 'Scan Facebook Posts'}
          </button>
          <button onClick={checkFacebookAccess} className={styles.checkButton}>
            Check Facebook Access
          </button>
          <button onClick={testFacebookApi} className={styles.testButton}>
            Test Facebook API
          </button>
          <button onClick={fetchFacebookPosts} className={styles.fetchButton}>
            Fetch Facebook Posts
          </button>
          {/* Add this JSX near the end of your return statement, perhaps after the other buttons */}
          <div className={styles.tokenUpdateSection}>
            <input
              type="text"
              value={newToken}
              onChange={(e) => setNewToken(e.target.value)}
              placeholder="Paste new Facebook token here"
              className={styles.tokenInput}
            />
            <button 
              onClick={updateFacebookToken} 
              className={styles.updateTokenButton}
              disabled={updatingToken}
            >
              {updatingToken ? 'Updating...' : 'Update Facebook Token'}
            </button>
          </div>
        </div>
      </div>
      {showPromptEditor && (
        <div className={styles.promptEditor}>
          <h3>Edit AI Prompt</h3>
          <textarea
            value={prompt}
            onChange={handlePromptChange}
            className={styles.promptTextarea}
          />
          <p className={styles.promptHelp}>
            Use {'{{businessName}}'}, {'{{bizWebsite}}'}, {'{{rawBizContent}}'}, and {'{{type}}'} as placeholders for each post's business name, website, content, and type.
          </p>
        </div>
      )}
      {posts.length > 0 ? (
        <Table 
          headers={headers} 
          data={formattedPosts} 
          keyField="_id"
          onRowClick={handlePostClick}
          disableRowClick={true}
          columnWidths={columnWidths}
        />
      ) : (
        <p>No posts found in this collection.</p>
      )}
      {showAddPostForm && (
        <AddPostForm
          onSubmit={handleAddPostSubmit}
          onCancel={handleAddPostCancel}
        />
      )}
      {showPageIdInput && (
        <div className={styles.pageIdInputContainer}>
          <input
            type="text"
            value={pageIdInput}
            onChange={(e) => setPageIdInput(e.target.value)}
            placeholder="Enter Facebook Page ID"
            className={styles.pageIdInput}
          />
          <button onClick={handlePageIdSubmit} className={styles.submitButton}>
            Submit
          </button>
          <button onClick={() => {
            setShowPageIdInput(false);
            setPageIdInput('');
            setCurrentAction('');
          }} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      )}
      {fetchedPosts.length > 0 && (
        <div className={styles.fetchedPosts}>
          <h3>Fetched Posts</h3>
          <ul>
            {fetchedPosts.map(post => (
              <li key={post.id}>
                {post.created_time}: {post.message ? post.message.substring(0, 50) + '...' : 'No message'}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CollectionPosts;
