import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from '../config';
import styles from './NewsletterEditor.module.css';

function NewsletterEditor() {
  const [newsletter, setNewsletter] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchNewsletter();
    fetchTemplates();
    fetchCollections();
  }, [id]);

  const fetchNewsletter = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/newsletters/${id}`);
      console.log('Fetched newsletter:', response.data);
      setNewsletter(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching newsletter:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch newsletter: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/email-templates`);
      console.log('Fetched templates:', response.data);
      setTemplates(response.data.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch templates: ' + (error.response?.data?.message || error.message));
    }
  };

  const fetchCollections = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/content-collections`);
      console.log('Fetched collections:', response.data);
      setCollections(response.data.data);
    } catch (error) {
      console.error('Error fetching collections:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch collections: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'template') {
      const selectedTemplate = templates.find(t => t._id === value);
      if (selectedTemplate) {
        setNewsletter(prev => ({
          ...prev,
          template: value,
          subject: selectedTemplate.subject,
          content: selectedTemplate.htmlContent
        }));
      } else {
        setNewsletter(prev => ({
          ...prev,
          template: value
        }));
      }
    } else {
      setNewsletter(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedNewsletter = {
        ...newsletter,
        template: newsletter.template || null,
        collectionId: newsletter.collectionId || null,
      };
      const response = await axios.put(`${getApiUrl()}/api/newsletters/${id}`, updatedNewsletter);
      console.log('Newsletter updated:', response.data);
      setError('Newsletter updated successfully');
      fetchNewsletter();
    } catch (error) {
      console.error('Error updating newsletter:', error);
      console.error('Error response:', error.response);
      setError('Failed to update newsletter: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleSend = async () => {
    try {
      console.log('Attempting to send newsletter:', id);
      const response = await axios.post(`${getApiUrl()}/api/newsletters/send/${id}`);
      console.log('Send response:', response.data);
      setError(`Newsletter sent successfully to ${response.data.recipientCount} recipients`);
    } catch (error) {
      console.error('Error sending newsletter:', error);
      console.error('Error response:', error.response?.data);
      setError('Failed to send newsletter: ' + (error.response?.data?.message || error.message));
    }
  };

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (!newsletter) return <div className={styles.error}>Newsletter not found</div>;

  return (
    <div className={styles.editorContainer}>
      <h2 className={styles.editorTitle}>Edit Newsletter</h2>
      {error && <div className={styles.error}>{error}</div>}
      <form onSubmit={handleSubmit} className={styles.editorForm}>
        <div className={styles.formGroup}>
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={newsletter.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={newsletter.subject}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="template">Template:</label>
          <select
            id="template"
            name="template"
            value={newsletter.template || ''}
            onChange={handleChange}
          >
            <option value="">No template</option>
            {templates.map(template => (
              <option key={template._id} value={template._id}>{template.name}</option>
            ))}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="collectionId">Content Collection:</label>
          <select
            id="collectionId"
            name="collectionId"
            value={newsletter.collectionId || ''}
            onChange={handleChange}
            required
          >
            <option value="">Select a collection</option>
            {collections.map(collection => (
              <option key={collection._id} value={collection._id}>{collection.name}</option>
            ))}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="content">Content:</label>
          <textarea
            id="content"
            name="content"
            value={newsletter.content}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.buttonGroup}>
          <button type="submit" className={styles.submitButton}>Update Newsletter</button>
          <button type="button" onClick={handleSend} className={styles.sendButton}>Send Newsletter</button>
        </div>
      </form>
    </div>
  );
}

export default NewsletterEditor;