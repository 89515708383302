import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from '../config'; // Add this import
import styles from './Templates.module.css';

function Templates() {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/email-templates`);
      console.log('Fetched templates:', response.data);
      setTemplates(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching templates:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch templates: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      try {
        await axios.delete(`${getApiUrl()}/api/email-templates/${id}`);
        console.log('Template deleted:', id);
        setTemplates(templates.filter(template => template._id !== id));
      } catch (error) {
        console.error('Error deleting template:', error);
        console.error('Error response:', error.response);
        setError('Failed to delete template: ' + (error.response?.data?.message || error.message));
      }
    }
  };

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.templatesContainer}>
      <h2>Email Templates</h2>
      <Link to="/dashboard/new-template" className={styles.createButton}>Create New Template</Link>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.templateList}>
        {templates.map(template => (
          <div key={template._id} className={styles.templateItem}>
            <h3>{template.name}</h3>
            <p>Subject: {template.subject}</p>
            <div className={styles.templateActions}>
              <Link to={`/dashboard/template-editor/${template._id}`} className={styles.editButton}>Edit</Link>
              <button onClick={() => handleDelete(template._id)} className={styles.deleteButton}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Templates;
