import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './GrantAccess.module.css';
import api from '../api';

function GrantAccess() {
  const [accessGranted, setAccessGranted] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleFacebookLogin = () => {
    console.log('Initiating Facebook login...');
    window.FB.login(function(response) {
      console.log('Facebook login response:', response);
      if (response.authResponse) {
        console.log('Authentication successful, fetching page information...');
        window.FB.api('/me/accounts', { fields: 'id,name,access_token,category,category_list,tasks,picture,cover,about,description,phone,emails,website,location,parking,hours,price_range,impressum,products,restaurant_services,restaurant_specialties' }, function(response) {
          console.log('Page information response:', response);
          if (response && response.data && response.data.length > 0) {
            const page = response.data[0];
            console.log('Selected page:', page);
            saveFacebookAccess(page);
          } else {
            console.error('No page data received');
            setError('Failed to retrieve page information. Please try again.');
          }
        });
      } else {
        console.log('User cancelled login or did not fully authorize.');
        setError('Facebook login was cancelled or not fully authorized. Please try again.');
      }
    }, {scope: 'pages_show_list,pages_read_engagement,pages_manage_metadata'});
  };

  const saveFacebookAccess = async (pageData) => {
    console.log('Attempting to save Facebook access data:', pageData);
    try {
      const response = await api.post('/api/facebook-access', pageData);
      console.log('Facebook access saved/updated:', response.data);
      setAccessGranted(true);
      setError(null);
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
    } catch (error) {
      console.error('Error saving Facebook access:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setError('Failed to save Facebook access. Please try again later.');
    }
  };

  if (accessGranted) {
    return (
      <div className={styles.container}>
        <h1 className={styles.title}>Thank You!</h1>
        <p className={styles.description}>
          Your Facebook Business Page access has been successfully granted. We appreciate your trust in our service.
        </p>
        <p className={styles.description}>
          You will be redirected to the dashboard shortly...
        </p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Grant Access to Your Facebook Business Page</h1>
      <p className={styles.description}>
        Click the button below to allow us to access your Facebook Business Page data.
      </p>
      {error && <p className={styles.error}>{error}</p>}
      <button onClick={handleFacebookLogin} className={styles.facebookButton}>
        Login with Facebook
      </button>
    </div>
  );
}

export default GrantAccess;