import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './PostEditor.module.css';

function PostEditor() {
  const [post, setPost] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { postId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPost();
    fetchBusinesses();
  }, [postId]);

  const fetchPost = async () => {
    try {
      const response = await axios.get(`/api/posts/${postId}`);
      console.log('Fetched post:', response.data);
      setPost(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching post:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch post: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  };

  const fetchBusinesses = async () => {
    try {
      const response = await axios.get('/api/businesses');
      console.log('Fetched businesses:', response.data);
      setBusinesses(response.data.data);
    } catch (error) {
      console.error('Error fetching businesses:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch businesses: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPost(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`/api/posts/${postId}`, post);
      console.log('Post updated:', response.data);
      setError('Post updated successfully');
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error('Error updating post:', error);
      console.error('Error response:', error.response);
      setError('Failed to update post: ' + (error.response?.data?.message || error.message));
    }
  };

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!post) return <div className={styles.error}>Post not found</div>;

  return (
    <div className={styles.editorContainer}>
      <h2 className={styles.editorTitle}>Edit Post</h2>
      {error && <div className={styles.error}>{error}</div>}
      <form onSubmit={handleSubmit} className={styles.editorForm}>
        <div className={styles.formGroup}>
          <label htmlFor="rawBizContent">Raw Business Content:</label>
          <textarea
            id="rawBizContent"
            name="rawBizContent"
            value={post.rawBizContent}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="businessId">Business:</label>
          <select
            id="businessId"
            name="businessId"
            value={post.businessId}
            onChange={handleChange}
            required
          >
            <option value="">Select a business</option>
            {businesses.map(business => (
              <option key={business._id} value={business._id}>{business.name}</option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="type">Type:</label>
          <select
            id="type"
            name="type"
            value={post.type || ''}
            onChange={handleChange}
          >
            <option value="">Select type</option>
            <option value="Sale">Sale</option>
            <option value="Promotion">Promotion</option>
            <option value="Event">Event</option>
            <option value="Update">Update</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="aiOutput">AI Output:</label>
          <textarea
            id="aiOutput"
            name="aiOutput"
            value={post.aiOutput || ''}
            onChange={handleChange}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="finalCopy">Final Copy (AI Generated):</label>
          <textarea
            id="finalCopy"
            name="finalCopy"
            value={post.finalCopy || ''}
            onChange={handleChange}
          />
        </div>

        <div className={styles.checkboxGroup}>
          <input
            type="checkbox"
            id="include"
            name="include"
            checked={post.include}
            onChange={handleChange}
          />
          <label htmlFor="include">Include in newsletter</label>
        </div>

        <div className={styles.buttonGroup}>
          <button type="button" onClick={() => navigate(-1)} className={styles.cancelButton}>Cancel</button>
          <button type="submit" className={styles.submitButton}>Save Changes</button>
        </div>
      </form>
    </div>
  );
}

export default PostEditor;
