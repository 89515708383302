import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Add useNavigate here
import axios from 'axios';
import { getApiUrl } from '../config'; // Add this import
import styles from './TemplateEditor.module.css';

function TemplateEditor() {
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTemplate();
  }, [id]);

  const fetchTemplate = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/email-templates/${id}`);
      console.log('Fetched template:', response.data);
      setTemplate(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching template:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch template: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setTemplate({ ...template, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${getApiUrl()}/api/email-templates/${id}`, template);
      console.log('Template updated:', response.data);
      setError('Template updated successfully');
      setTimeout(() => navigate('/dashboard/templates'), 2000);
    } catch (error) {
      console.error('Error updating template:', error);
      console.error('Error response:', error.response);
      setError('Failed to update template: ' + (error.response?.data?.message || error.message));
    }
  };

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (!template) return <div className={styles.error}>Template not found</div>;

  return (
    <div className={styles.editorContainer}>
      <h2>Edit Email Template</h2>
      {error && <div className={styles.error}>{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Template Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={template.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={template.subject}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="htmlContent">HTML Content:</label>
          <textarea
            id="htmlContent"
            name="htmlContent"
            value={template.htmlContent}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className={styles.submitButton}>Update Template</button>
      </form>
    </div>
  );
}

export default TemplateEditor;
