import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from '../config'; // Add this import
import styles from './NewBusiness.module.css';

function NewBusiness() {
  const [business, setBusiness] = useState({
    name: '',
    website: '',
    facebookURL: '',
    instagramURL: '',
    XURL: '',
    zipCode: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setBusiness({ ...business, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${getApiUrl()}/api/businesses`, business);
      console.log('Business created:', response.data);
      navigate('/dashboard/businesses');
    } catch (error) {
      console.error('Error creating business:', error);
      console.error('Error response:', error.response);
      setError('Failed to create business: ' + (error.response?.data?.message || error.message));
    }
  };

  return (
    <div className={styles.newBusinessContainer}>
      <h2>Add New Business</h2>
      {error && <p className={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={business.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="website">Website:</label>
          <input
            type="text"
            id="website"
            name="website"
            value={business.website}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="facebookURL">Facebook URL:</label>
          <input
            type="text"
            id="facebookURL"
            name="facebookURL"
            value={business.facebookURL}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="instagramURL">Instagram URL:</label>
          <input
            type="text"
            id="instagramURL"
            name="instagramURL"
            value={business.instagramURL}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="XURL">X URL:</label>
          <input
            type="text"
            id="XURL"
            name="XURL"
            value={business.XURL}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="zipCode">Zip Code:</label>
          <input
            type="text"
            id="zipCode"
            name="zipCode"
            value={business.zipCode}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className={styles.submitButton}>Create Business</button>
      </form>
    </div>
  );
}

export default NewBusiness;