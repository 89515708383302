import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from '../config'; // Add this import
import styles from './NewNewsletter.module.css';

function NewNewsletter() {
  const [newsletter, setNewsletter] = useState({ 
    subject: '', 
    content: '', 
    template: '', 
    collectionId: '' 
  });
  const [templates, setTemplates] = useState([]);
  const [collections, setCollections] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchTemplates();
    fetchCollections();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/email-templates`);
      console.log('Fetched templates:', response.data);
      setTemplates(response.data.data || []);
    } catch (error) {
      console.error('Error fetching templates:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch templates: ' + (error.response?.data?.message || error.message));
    }
  };

  const fetchCollections = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/content-collections`);
      console.log('Fetched collections:', response.data);
      setCollections(response.data.data || []);
    } catch (error) {
      console.error('Error fetching collections:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch collections: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewsletter(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'template') {
      const selectedTemplate = templates.find(t => t._id === value);
      if (selectedTemplate) {
        setNewsletter(prev => ({
          ...prev,
          subject: selectedTemplate.subject,
          content: selectedTemplate.htmlContent
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${getApiUrl()}/api/newsletters`, newsletter);
      console.log('Newsletter created:', response.data);
      navigate('/dashboard/newsletters');
    } catch (error) {
      console.error('Error creating newsletter:', error);
      console.error('Error response:', error.response);
      setError('Failed to create newsletter: ' + (error.response?.data?.message || error.message));
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Create New Newsletter</h2>
      {error && <p className={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="template">Template:</label>
          <select
            id="template"
            name="template"
            value={newsletter.template}
            onChange={handleChange}
          >
            <option value="">Select a template</option>
            {templates.map(template => (
              <option key={template._id} value={template._id}>{template.name}</option>
            ))}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={newsletter.subject}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="content">Content:</label>
          <textarea
            id="content"
            name="content"
            value={newsletter.content}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="collectionId">Collection:</label>
          <select
            id="collectionId"
            name="collectionId"
            value={newsletter.collectionId}
            onChange={handleChange}
            required
          >
            <option value="">Select a collection</option>
            {collections.map(collection => (
              <option key={collection._id} value={collection._id}>{collection.name}</option>
            ))}
          </select>
        </div>
        <button type="submit" className={styles.submitButton}>Create Newsletter</button>
      </form>
    </div>
  );
}

export default NewNewsletter;