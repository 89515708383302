import React, { useState } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { getApiUrl } from '../config.js';
import Newsletters from './Newsletters';
import Users from './Users';
import Templates from './Templates.js';
import NewUser from './NewUser';
import NewNewsletter from './NewNewsletter';
import NewsletterEditor from './NewsletterEditor';
import NewTemplate from './NewTemplate';
import TemplateEditor from './TemplateEditor';
import ContentManager from './ContentManager';
import CollectionPosts from './CollectionPosts';
import Businesses from './Businesses';
import BusinessEditor from './BusinessEditor';
import NewBusiness from './NewBusiness';
import PostEditor from './PostEditor';
import styles from './Dashboard.module.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Dashboard() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [activePopover, setActivePopover] = useState(null);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  if (!user) {
    return null;
  }

  const navItems = [
    { emoji: '🏠', label: 'Dashboard', path: '/dashboard' },
    { emoji: '👥', label: 'Users', path: '/dashboard/users' },
    { emoji: '📧', label: 'Newsletters', path: '/dashboard/newsletters' },
    { emoji: '🖼️', label: 'Templates', path: '/dashboard/templates' },
    { emoji: '🧶', label: 'Content Manager', path: '/dashboard/content-manager' },
    { emoji: '🏢', label: 'Businesses', path: '/dashboard/businesses' },
    { emoji: '🔐', label: 'Grant Access', path: '/grant-access' },
  ];

  return (
    <div className={styles.dashboardContainer}>
      <nav className={styles.verticalNav}>
        <ul>
          {navItems.map((item, index) => (
            <li key={index} 
                onMouseEnter={() => setActivePopover(index)}
                onMouseLeave={() => setActivePopover(null)}>
              <Link to={item.path}>
                <span className={styles.navEmoji}>{item.emoji}</span>
                {activePopover === index && (
                  <span className={styles.navPopover}>{item.label}</span>
                )}
              </Link>
            </li>
          ))}
        </ul>
        <button onClick={handleLogout} className={styles.logoutButton}>🚪</button>
      </nav>
      <main className={styles.dashboardContent}>
        <Routes>
          <Route index element={<DashboardHome />} />
          <Route path="newsletters" element={<Newsletters />} />
          <Route path="users" element={<Users />} />
          <Route path="new-user" element={<NewUser />} />
          <Route path="new-newsletter" element={<NewNewsletter />} />
          <Route path="newsletter-editor/:id" element={<NewsletterEditor />} />
          <Route path="templates" element={<Templates />} />
          <Route path="new-template" element={<NewTemplate />} />
          <Route path="template-editor/:id" element={<TemplateEditor />} />
          <Route path="content-manager" element={<ContentManager />} />
          <Route path="collection/:collectionId/posts" element={<CollectionPosts />} />
          <Route path="businesses" element={<Businesses />} />
          <Route path="business-editor/:id" element={<BusinessEditor />} />
          <Route path="new-business" element={<NewBusiness />} />
          <Route path="edit-post/:postId" element={<PostEditor />} />
        </Routes>
      </main>
    </div>
  );
}

const DashboardHome = () => {
  const [businessData, setBusinessData] = useState(null);
  const [error, setError] = useState(null);

  React.useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const response = await axios.get(`${getApiUrl()}/api/businesses/by-zipcode`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Fetched business data:', response.data);
        setBusinessData(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching business data:', error);
        console.error('Error response:', error.response?.data);
        setError('Failed to fetch business data: ' + (error.response?.data?.message || error.message));
        setBusinessData(null);
      }
    };

    fetchBusinessData();
  }, []);

  const chartData = {
    labels: businessData ? Object.keys(businessData) : [],
    datasets: [
      {
        label: 'Number of Businesses',
        data: businessData ? Object.values(businessData) : [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Businesses by Zip Code',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Businesses',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Zip Code',
        },
      },
    },
  };

  return (
    <div className={styles.card}>
      <h2>Businesses by Zip Code</h2>
      {error && <p className={styles.error}>{error}</p>}
      {businessData ? (
        <Bar data={chartData} options={options} />
      ) : (
        <p>Loading business data...</p>
      )}
    </div>
  );
};

export default Dashboard;